export default defineAppConfig({
    ui: {
        strategy: 'override',
        primary: 'cyan',
        gray: 'cool',
        container: {
            ring: 'ring-2 ring-slate-200 dark:ring-slate-800',
            rounded: 'rounded-sm',
            background: 'bg-slate-50 dark:bg-slate-800 rounded',
            text: 'text-slate-900 dark:text-slate-100',
        },
        card: {
            ring: 'ring-1 ring-slate-200 dark:ring-slate-700',
            rounded: 'rounded-sm',
            background: 'bg-slate-50 dark:bg-slate-800',
            header: {
                background: 'dark:bg-slate-800',
            },
            body: {},
            footer: {},
            text: 'text-slate-900 dark:text-slate-100',
        },
        // modal: {
        //   overlay: {
        //     background: "bg-slate-50 dark:bg-red-800",
        //   }
        // },
        notifications: {
            position: 'top-0 right-0',
            background: 'bg-slate-100',
        },
        popover: {
            background: 'bg-slate-100 dark:bg-slate-800',
        },
        // IF USING A DEFAULT BUTTON COLOR, SAFELIST IN NUXT.CONFIG.TS
        // button: {
        //   "color": {
        //     "cyan": {
        //       "solid": "text-white dark:text-white bg-primary-500 hover:bg-primary-600 dark:bg-primary-400 dark:hover:bg-primary-500"
        //     },
        //   }
        // },
        badge: {
            //text: "text-white dark:text-white",
            color: {
                red: {
                    solid: 'text-white dark:text-white bg-red-500 dark:bg-red-400',
                },
                green: {
                    solid: 'text-white dark:text-white bg-green-500 dark:bg-green-400',
                },
                amber: {
                    solid: 'text-white dark:text-white bg-amber-500 dark:bg-amber-400',
                },
            },
        },
        tooltip: {
            popper: {
                offsetDistance: 16,
            }
        }
    },
})
