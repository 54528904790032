import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import role_45checker_45global from "/vercel/path0/middleware/roleChecker.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  role_45checker_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "01-redirect-auth": () => import("/vercel/path0/middleware/01.redirectAuth.ts"),
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  logger: () => import("/vercel/path0/middleware/logger.ts")
}