import { createAuth0 } from "@auth0/auth0-vue";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if(from.path != '/auth/logged-in')
    if(process.client){
      localStorage.setItem("redirectTo", from.path);
    }

  const config = useRuntimeConfig()
  if (process.client) {
    // console.log('roleChecker')
    // console.log('to: ' + to.path)
    // console.log('from: ' + from.path)
    const { $auth0 } = await useNuxtApp();


    if ($auth0.isAuthenticated.value) {

      const userInfo = JSON.parse(JSON.stringify($auth0?.user.value))
        let userRoles = [];
        if (typeof userInfo[config.public.auth0UserRolesResponseKey] !== 'undefined')
          userRoles = userInfo[config.public.auth0UserRolesResponseKey]


        let pageInfo = JSON.parse(JSON.stringify(to.meta))
        // console.log(pageInfo)


        if (pageInfo?.auth?.roles && pageInfo?.auth?.roles.length > 0 ){
          if(userRoles.length == 0)
             return navigateTo('/')
          if (!pageInfo?.auth?.roles.includes(userRoles[0])) {
             return navigateTo('/')
          }
        }

    }
  }
});
