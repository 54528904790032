import { default as document_45drivenxLjo3Ekp33Meta } from "/vercel/path0/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue?macro=true";
import { default as kitchen_45sinkR7qNW6GHjxMeta } from "/vercel/path0/pages/_test-pages/kitchen-sink.vue?macro=true";
import { default as test_45checkout3WRNLyV2qXMeta } from "/vercel/path0/pages/_test-pages/test-checkout.vue?macro=true";
import { default as test_45invoicesf6BypCmdbxMeta } from "/vercel/path0/pages/_test-pages/test-invoices.vue?macro=true";
import { default as test_45manage_45dnsOYVtdqJLsdMeta } from "/vercel/path0/pages/_test-pages/test-manage-dns.vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as logged_45innS1gX9ukHRMeta } from "/vercel/path0/pages/auth/logged-in.vue?macro=true";
import { default as sign_45outYP8pbFWMO2Meta } from "/vercel/path0/pages/auth/sign-out.vue?macro=true";
import { default as checkout_45success9SJOZS4naKMeta } from "/vercel/path0/pages/cart/checkout-success.vue?macro=true";
import { default as indextHkCpcsk7qMeta } from "/vercel/path0/pages/cart/index.vue?macro=true";
import { default as indexsN4kZYqWgXMeta } from "/vercel/path0/pages/dashboard/admin/activity-table/index.vue?macro=true";
import { default as indexhKYA9EVuFcMeta } from "/vercel/path0/pages/dashboard/admin/activity/index.vue?macro=true";
import { default as indexAtISuKBaisMeta } from "/vercel/path0/pages/dashboard/admin/dp-config/index.vue?macro=true";
import { default as addypD9FC2NFxMeta } from "/vercel/path0/pages/dashboard/admin/organizations/add.vue?macro=true";
import { default as Edit3Cu0ySfO0zMeta } from "/vercel/path0/pages/dashboard/admin/organizations/Edit.vue?macro=true";
import { default as indexHx5almYmqmMeta } from "/vercel/path0/pages/dashboard/admin/organizations/index.vue?macro=true";
import { default as ViewoOr4QDSjeZMeta } from "/vercel/path0/pages/dashboard/admin/organizations/View.vue?macro=true";
import { default as indexUxNXjXaC5NMeta } from "/vercel/path0/pages/dashboard/admin/price-list-friends/index.vue?macro=true";
import { default as indexuG7fvLABiNMeta } from "/vercel/path0/pages/dashboard/admin/price-list-resellers/index.vue?macro=true";
import { default as AddUt9cSJ63wyMeta } from "/vercel/path0/pages/dashboard/admin/price-list/Add.vue?macro=true";
import { default as editsagb6s0P2zMeta } from "/vercel/path0/pages/dashboard/admin/price-list/edit.vue?macro=true";
import { default as indexWHMbbiwdAiMeta } from "/vercel/path0/pages/dashboard/admin/price-list/index.vue?macro=true";
import { default as viewIArDF8ECmjMeta } from "/vercel/path0/pages/dashboard/admin/price-list/view.vue?macro=true";
import { default as add5VHbAZdYw7Meta } from "/vercel/path0/pages/dashboard/admin/users/add.vue?macro=true";
import { default as editQTUfYyugIGMeta } from "/vercel/path0/pages/dashboard/admin/users/edit.vue?macro=true";
import { default as indexqmnSSCJf6xMeta } from "/vercel/path0/pages/dashboard/admin/users/index.vue?macro=true";
import { default as viewYE5NkecImVMeta } from "/vercel/path0/pages/dashboard/admin/users/view.vue?macro=true";
import { default as indexF7hjGorvlHMeta } from "/vercel/path0/pages/dashboard/billing/index.vue?macro=true";
import { default as indexPOWkWhV2jMMeta } from "/vercel/path0/pages/dashboard/billing/invoices/index.vue?macro=true";
import { default as indexu2QtERsFGmMeta } from "/vercel/path0/pages/dashboard/billing/plan/index.vue?macro=true";
import { default as indexR6yuefLMt8Meta } from "/vercel/path0/pages/dashboard/billing/subscriptions/index.vue?macro=true";
import { default as _91id_931fJJPM0Cq2Meta } from "/vercel/path0/pages/dashboard/domain/register/[orderId]/[id].vue?macro=true";
import { default as _91id_93O1d3s1RwalMeta } from "/vercel/path0/pages/dashboard/domain/transfer/[orderId]/[id].vue?macro=true";
import { default as indexVF7RuT4si4Meta } from "/vercel/path0/pages/dashboard/domains/[id]/index.vue?macro=true";
import { default as manage_45dnsBiNvM1MLW1Meta } from "/vercel/path0/pages/dashboard/domains/[id]/manage-dns.vue?macro=true";
import { default as _91id_93UTVAYOIJfsMeta } from "/vercel/path0/pages/dashboard/domains/expiring/[id].vue?macro=true";
import { default as indexdm1nzDTQgKMeta } from "/vercel/path0/pages/dashboard/domains/expiring/index.vue?macro=true";
import { default as indexAiCSepVFf5Meta } from "/vercel/path0/pages/dashboard/domains/index.vue?macro=true";
import { default as indexcoyydRllC0Meta } from "/vercel/path0/pages/dashboard/domains/register/index.vue?macro=true";
import { default as indexNoMuhcUaf3Meta } from "/vercel/path0/pages/dashboard/domains/transfers/index.vue?macro=true";
import { default as indexw3LbGfJ0hPMeta } from "/vercel/path0/pages/dashboard/domains/whois/index.vue?macro=true";
import { default as indexFtYuxFtR1wMeta } from "/vercel/path0/pages/dashboard/help/index.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as checkout_45successWg2sZIvYDxMeta } from "/vercel/path0/pages/dashboard/local-domain-registration-service/checkout-success.vue?macro=true";
import { default as indexDRHviHFhOtMeta } from "/vercel/path0/pages/dashboard/local-domain-registration-service/index.vue?macro=true";
import { default as indexW7xJWPe76nMeta } from "/vercel/path0/pages/dashboard/profile/index.vue?macro=true";
import { default as indexezgjwScBnSMeta } from "/vercel/path0/pages/dashboard/profile/notifications/index.vue?macro=true";
import { default as _91id_93XP2kLoUuk0Meta } from "/vercel/path0/pages/dashboard/profile/organizations/[id].vue?macro=true";
import { default as indexBTTwV34nSrMeta } from "/vercel/path0/pages/dashboard/profile/organizations/index.vue?macro=true";
import { default as _91id_93lLsxKUPO3vMeta } from "/vercel/path0/pages/dashboard/registrars/[id].vue?macro=true";
import { default as indexQkYxMWqJKzMeta } from "/vercel/path0/pages/dashboard/registrars/godaddy/index.vue?macro=true";
import { default as index2Yk5uCDZPEMeta } from "/vercel/path0/pages/dashboard/registrars/index.vue?macro=true";
import { default as indexg2kmA2a7U1Meta } from "/vercel/path0/pages/dashboard/reports/index.vue?macro=true";
import { default as indexsYg5vFPOgKMeta } from "/vercel/path0/pages/dashboard/settings/index.vue?macro=true";
import { default as indexiid3aQUQF8Meta } from "/vercel/path0/pages/dashboard/support/index.vue?macro=true";
import { default as indexkK6SLClYNYMeta } from "/vercel/path0/pages/domain-registration/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexGnHiNaJdjPMeta } from "/vercel/path0/pages/legal/index.vue?macro=true";
import { default as index9Oq1976rm7Meta } from "/vercel/path0/pages/legal/privacy-policy/index.vue?macro=true";
import { default as indexLdRQIa2Tt3Meta } from "/vercel/path0/pages/legal/terms-of-service/index.vue?macro=true";
import { default as indexrQEGMN64RqMeta } from "/vercel/path0/pages/local-domain-registration-service/index.vue?macro=true";
import { default as indexdtTG75hJaKMeta } from "/vercel/path0/pages/search-for-domains/index.vue?macro=true";
import { default as checkout_45successlyzrVCeVxlMeta } from "/vercel/path0/pages/transfer-domains/checkout-success.vue?macro=true";
import { default as index6LNRyE6A5YMeta } from "/vercel/path0/pages/transfer-domains/index.vue?macro=true";
export default [
  {
    name: document_45drivenxLjo3Ekp33Meta?.name ?? "slug",
    path: document_45drivenxLjo3Ekp33Meta?.path ?? "/:slug(.*)*",
    meta: document_45drivenxLjo3Ekp33Meta || {},
    alias: document_45drivenxLjo3Ekp33Meta?.alias || [],
    redirect: document_45drivenxLjo3Ekp33Meta?.redirect,
    component: () => import("/vercel/path0/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue").then(m => m.default || m)
  },
  {
    name: kitchen_45sinkR7qNW6GHjxMeta?.name ?? "_test-pages-kitchen-sink",
    path: kitchen_45sinkR7qNW6GHjxMeta?.path ?? "/_test-pages/kitchen-sink",
    meta: kitchen_45sinkR7qNW6GHjxMeta || {},
    alias: kitchen_45sinkR7qNW6GHjxMeta?.alias || [],
    redirect: kitchen_45sinkR7qNW6GHjxMeta?.redirect,
    component: () => import("/vercel/path0/pages/_test-pages/kitchen-sink.vue").then(m => m.default || m)
  },
  {
    name: test_45checkout3WRNLyV2qXMeta?.name ?? "_test-pages-test-checkout",
    path: test_45checkout3WRNLyV2qXMeta?.path ?? "/_test-pages/test-checkout",
    meta: test_45checkout3WRNLyV2qXMeta || {},
    alias: test_45checkout3WRNLyV2qXMeta?.alias || [],
    redirect: test_45checkout3WRNLyV2qXMeta?.redirect,
    component: () => import("/vercel/path0/pages/_test-pages/test-checkout.vue").then(m => m.default || m)
  },
  {
    name: test_45invoicesf6BypCmdbxMeta?.name ?? "_test-pages-test-invoices",
    path: test_45invoicesf6BypCmdbxMeta?.path ?? "/_test-pages/test-invoices",
    meta: test_45invoicesf6BypCmdbxMeta || {},
    alias: test_45invoicesf6BypCmdbxMeta?.alias || [],
    redirect: test_45invoicesf6BypCmdbxMeta?.redirect,
    component: () => import("/vercel/path0/pages/_test-pages/test-invoices.vue").then(m => m.default || m)
  },
  {
    name: test_45manage_45dnsOYVtdqJLsdMeta?.name ?? "_test-pages-test-manage-dns",
    path: test_45manage_45dnsOYVtdqJLsdMeta?.path ?? "/_test-pages/test-manage-dns",
    meta: test_45manage_45dnsOYVtdqJLsdMeta || {},
    alias: test_45manage_45dnsOYVtdqJLsdMeta?.alias || [],
    redirect: test_45manage_45dnsOYVtdqJLsdMeta?.redirect,
    component: () => import("/vercel/path0/pages/_test-pages/test-manage-dns.vue").then(m => m.default || m)
  },
  {
    name: indexkppHktB81xMeta?.name ?? "auth",
    path: indexkppHktB81xMeta?.path ?? "/auth",
    meta: indexkppHktB81xMeta || {},
    alias: indexkppHktB81xMeta?.alias || [],
    redirect: indexkppHktB81xMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: logged_45innS1gX9ukHRMeta?.name ?? "auth-logged-in",
    path: logged_45innS1gX9ukHRMeta?.path ?? "/auth/logged-in",
    meta: logged_45innS1gX9ukHRMeta || {},
    alias: logged_45innS1gX9ukHRMeta?.alias || [],
    redirect: logged_45innS1gX9ukHRMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/logged-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45outYP8pbFWMO2Meta?.name ?? "auth-sign-out",
    path: sign_45outYP8pbFWMO2Meta?.path ?? "/auth/sign-out",
    meta: sign_45outYP8pbFWMO2Meta || {},
    alias: sign_45outYP8pbFWMO2Meta?.alias || [],
    redirect: sign_45outYP8pbFWMO2Meta?.redirect,
    component: () => import("/vercel/path0/pages/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: checkout_45success9SJOZS4naKMeta?.name ?? "cart-checkout-success",
    path: checkout_45success9SJOZS4naKMeta?.path ?? "/cart/checkout-success",
    meta: checkout_45success9SJOZS4naKMeta || {},
    alias: checkout_45success9SJOZS4naKMeta?.alias || [],
    redirect: checkout_45success9SJOZS4naKMeta?.redirect,
    component: () => import("/vercel/path0/pages/cart/checkout-success.vue").then(m => m.default || m)
  },
  {
    name: indextHkCpcsk7qMeta?.name ?? "cart",
    path: indextHkCpcsk7qMeta?.path ?? "/cart",
    meta: indextHkCpcsk7qMeta || {},
    alias: indextHkCpcsk7qMeta?.alias || [],
    redirect: indextHkCpcsk7qMeta?.redirect,
    component: () => import("/vercel/path0/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexsN4kZYqWgXMeta?.name ?? "dashboard-admin-activity-table",
    path: indexsN4kZYqWgXMeta?.path ?? "/dashboard/admin/activity-table",
    meta: indexsN4kZYqWgXMeta || {},
    alias: indexsN4kZYqWgXMeta?.alias || [],
    redirect: indexsN4kZYqWgXMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/activity-table/index.vue").then(m => m.default || m)
  },
  {
    name: indexhKYA9EVuFcMeta?.name ?? "dashboard-admin-activity",
    path: indexhKYA9EVuFcMeta?.path ?? "/dashboard/admin/activity",
    meta: indexhKYA9EVuFcMeta || {},
    alias: indexhKYA9EVuFcMeta?.alias || [],
    redirect: indexhKYA9EVuFcMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/activity/index.vue").then(m => m.default || m)
  },
  {
    name: indexAtISuKBaisMeta?.name ?? "dashboard-admin-dp-config",
    path: indexAtISuKBaisMeta?.path ?? "/dashboard/admin/dp-config",
    meta: indexAtISuKBaisMeta || {},
    alias: indexAtISuKBaisMeta?.alias || [],
    redirect: indexAtISuKBaisMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/dp-config/index.vue").then(m => m.default || m)
  },
  {
    name: addypD9FC2NFxMeta?.name ?? "dashboard-admin-organizations-add",
    path: addypD9FC2NFxMeta?.path ?? "/dashboard/admin/organizations/add",
    meta: addypD9FC2NFxMeta || {},
    alias: addypD9FC2NFxMeta?.alias || [],
    redirect: addypD9FC2NFxMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/organizations/add.vue").then(m => m.default || m)
  },
  {
    name: Edit3Cu0ySfO0zMeta?.name ?? "dashboard-admin-organizations-Edit",
    path: Edit3Cu0ySfO0zMeta?.path ?? "/dashboard/admin/organizations/Edit",
    meta: Edit3Cu0ySfO0zMeta || {},
    alias: Edit3Cu0ySfO0zMeta?.alias || [],
    redirect: Edit3Cu0ySfO0zMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/organizations/Edit.vue").then(m => m.default || m)
  },
  {
    name: indexHx5almYmqmMeta?.name ?? "dashboard-admin-organizations",
    path: indexHx5almYmqmMeta?.path ?? "/dashboard/admin/organizations",
    meta: indexHx5almYmqmMeta || {},
    alias: indexHx5almYmqmMeta?.alias || [],
    redirect: indexHx5almYmqmMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: ViewoOr4QDSjeZMeta?.name ?? "dashboard-admin-organizations-View",
    path: ViewoOr4QDSjeZMeta?.path ?? "/dashboard/admin/organizations/View",
    meta: ViewoOr4QDSjeZMeta || {},
    alias: ViewoOr4QDSjeZMeta?.alias || [],
    redirect: ViewoOr4QDSjeZMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/organizations/View.vue").then(m => m.default || m)
  },
  {
    name: indexUxNXjXaC5NMeta?.name ?? "dashboard-admin-price-list-friends",
    path: indexUxNXjXaC5NMeta?.path ?? "/dashboard/admin/price-list-friends",
    meta: indexUxNXjXaC5NMeta || {},
    alias: indexUxNXjXaC5NMeta?.alias || [],
    redirect: indexUxNXjXaC5NMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/price-list-friends/index.vue").then(m => m.default || m)
  },
  {
    name: indexuG7fvLABiNMeta?.name ?? "dashboard-admin-price-list-resellers",
    path: indexuG7fvLABiNMeta?.path ?? "/dashboard/admin/price-list-resellers",
    meta: indexuG7fvLABiNMeta || {},
    alias: indexuG7fvLABiNMeta?.alias || [],
    redirect: indexuG7fvLABiNMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/price-list-resellers/index.vue").then(m => m.default || m)
  },
  {
    name: AddUt9cSJ63wyMeta?.name ?? "dashboard-admin-price-list-Add",
    path: AddUt9cSJ63wyMeta?.path ?? "/dashboard/admin/price-list/Add",
    meta: AddUt9cSJ63wyMeta || {},
    alias: AddUt9cSJ63wyMeta?.alias || [],
    redirect: AddUt9cSJ63wyMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/price-list/Add.vue").then(m => m.default || m)
  },
  {
    name: editsagb6s0P2zMeta?.name ?? "dashboard-admin-price-list-edit",
    path: editsagb6s0P2zMeta?.path ?? "/dashboard/admin/price-list/edit",
    meta: editsagb6s0P2zMeta || {},
    alias: editsagb6s0P2zMeta?.alias || [],
    redirect: editsagb6s0P2zMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/price-list/edit.vue").then(m => m.default || m)
  },
  {
    name: indexWHMbbiwdAiMeta?.name ?? "dashboard-admin-price-list",
    path: indexWHMbbiwdAiMeta?.path ?? "/dashboard/admin/price-list",
    meta: indexWHMbbiwdAiMeta || {},
    alias: indexWHMbbiwdAiMeta?.alias || [],
    redirect: indexWHMbbiwdAiMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/price-list/index.vue").then(m => m.default || m)
  },
  {
    name: viewIArDF8ECmjMeta?.name ?? "dashboard-admin-price-list-view",
    path: viewIArDF8ECmjMeta?.path ?? "/dashboard/admin/price-list/view",
    meta: viewIArDF8ECmjMeta || {},
    alias: viewIArDF8ECmjMeta?.alias || [],
    redirect: viewIArDF8ECmjMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/price-list/view.vue").then(m => m.default || m)
  },
  {
    name: add5VHbAZdYw7Meta?.name ?? "dashboard-admin-users-add",
    path: add5VHbAZdYw7Meta?.path ?? "/dashboard/admin/users/add",
    meta: add5VHbAZdYw7Meta || {},
    alias: add5VHbAZdYw7Meta?.alias || [],
    redirect: add5VHbAZdYw7Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: editQTUfYyugIGMeta?.name ?? "dashboard-admin-users-edit",
    path: editQTUfYyugIGMeta?.path ?? "/dashboard/admin/users/edit",
    meta: editQTUfYyugIGMeta || {},
    alias: editQTUfYyugIGMeta?.alias || [],
    redirect: editQTUfYyugIGMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/users/edit.vue").then(m => m.default || m)
  },
  {
    name: indexqmnSSCJf6xMeta?.name ?? "dashboard-admin-users",
    path: indexqmnSSCJf6xMeta?.path ?? "/dashboard/admin/users",
    meta: indexqmnSSCJf6xMeta || {},
    alias: indexqmnSSCJf6xMeta?.alias || [],
    redirect: indexqmnSSCJf6xMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: viewYE5NkecImVMeta?.name ?? "dashboard-admin-users-view",
    path: viewYE5NkecImVMeta?.path ?? "/dashboard/admin/users/view",
    meta: viewYE5NkecImVMeta || {},
    alias: viewYE5NkecImVMeta?.alias || [],
    redirect: viewYE5NkecImVMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/users/view.vue").then(m => m.default || m)
  },
  {
    name: indexF7hjGorvlHMeta?.name ?? "dashboard-billing",
    path: indexF7hjGorvlHMeta?.path ?? "/dashboard/billing",
    meta: indexF7hjGorvlHMeta || {},
    alias: indexF7hjGorvlHMeta?.alias || [],
    redirect: indexF7hjGorvlHMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexPOWkWhV2jMMeta?.name ?? "dashboard-billing-invoices",
    path: indexPOWkWhV2jMMeta?.path ?? "/dashboard/billing/invoices",
    meta: indexPOWkWhV2jMMeta || {},
    alias: indexPOWkWhV2jMMeta?.alias || [],
    redirect: indexPOWkWhV2jMMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/billing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexu2QtERsFGmMeta?.name ?? "dashboard-billing-plan",
    path: indexu2QtERsFGmMeta?.path ?? "/dashboard/billing/plan",
    meta: indexu2QtERsFGmMeta || {},
    alias: indexu2QtERsFGmMeta?.alias || [],
    redirect: indexu2QtERsFGmMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/billing/plan/index.vue").then(m => m.default || m)
  },
  {
    name: indexR6yuefLMt8Meta?.name ?? "dashboard-billing-subscriptions",
    path: indexR6yuefLMt8Meta?.path ?? "/dashboard/billing/subscriptions",
    meta: indexR6yuefLMt8Meta || {},
    alias: indexR6yuefLMt8Meta?.alias || [],
    redirect: indexR6yuefLMt8Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/billing/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931fJJPM0Cq2Meta?.name ?? "dashboard-domain-register-orderId-id",
    path: _91id_931fJJPM0Cq2Meta?.path ?? "/dashboard/domain/register/:orderId()/:id()",
    meta: _91id_931fJJPM0Cq2Meta || {},
    alias: _91id_931fJJPM0Cq2Meta?.alias || [],
    redirect: _91id_931fJJPM0Cq2Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domain/register/[orderId]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93O1d3s1RwalMeta?.name ?? "dashboard-domain-transfer-orderId-id",
    path: _91id_93O1d3s1RwalMeta?.path ?? "/dashboard/domain/transfer/:orderId()/:id()",
    meta: _91id_93O1d3s1RwalMeta || {},
    alias: _91id_93O1d3s1RwalMeta?.alias || [],
    redirect: _91id_93O1d3s1RwalMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domain/transfer/[orderId]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVF7RuT4si4Meta?.name ?? "dashboard-domains-id",
    path: indexVF7RuT4si4Meta?.path ?? "/dashboard/domains/:id()",
    meta: indexVF7RuT4si4Meta || {},
    alias: indexVF7RuT4si4Meta?.alias || [],
    redirect: indexVF7RuT4si4Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: manage_45dnsBiNvM1MLW1Meta?.name ?? "dashboard-domains-id-manage-dns",
    path: manage_45dnsBiNvM1MLW1Meta?.path ?? "/dashboard/domains/:id()/manage-dns",
    meta: manage_45dnsBiNvM1MLW1Meta || {},
    alias: manage_45dnsBiNvM1MLW1Meta?.alias || [],
    redirect: manage_45dnsBiNvM1MLW1Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/[id]/manage-dns.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UTVAYOIJfsMeta?.name ?? "dashboard-domains-expiring-id",
    path: _91id_93UTVAYOIJfsMeta?.path ?? "/dashboard/domains/expiring/:id()",
    meta: _91id_93UTVAYOIJfsMeta || {},
    alias: _91id_93UTVAYOIJfsMeta?.alias || [],
    redirect: _91id_93UTVAYOIJfsMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/expiring/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdm1nzDTQgKMeta?.name ?? "dashboard-domains-expiring",
    path: indexdm1nzDTQgKMeta?.path ?? "/dashboard/domains/expiring",
    meta: indexdm1nzDTQgKMeta || {},
    alias: indexdm1nzDTQgKMeta?.alias || [],
    redirect: indexdm1nzDTQgKMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/expiring/index.vue").then(m => m.default || m)
  },
  {
    name: indexAiCSepVFf5Meta?.name ?? "dashboard-domains",
    path: indexAiCSepVFf5Meta?.path ?? "/dashboard/domains",
    meta: indexAiCSepVFf5Meta || {},
    alias: indexAiCSepVFf5Meta?.alias || [],
    redirect: indexAiCSepVFf5Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/index.vue").then(m => m.default || m)
  },
  {
    name: indexcoyydRllC0Meta?.name ?? "dashboard-domains-register",
    path: indexcoyydRllC0Meta?.path ?? "/dashboard/domains/register",
    meta: indexcoyydRllC0Meta || {},
    alias: indexcoyydRllC0Meta?.alias || [],
    redirect: indexcoyydRllC0Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexNoMuhcUaf3Meta?.name ?? "dashboard-domains-transfers",
    path: indexNoMuhcUaf3Meta?.path ?? "/dashboard/domains/transfers",
    meta: indexNoMuhcUaf3Meta || {},
    alias: indexNoMuhcUaf3Meta?.alias || [],
    redirect: indexNoMuhcUaf3Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/transfers/index.vue").then(m => m.default || m)
  },
  {
    name: indexw3LbGfJ0hPMeta?.name ?? "dashboard-domains-whois",
    path: indexw3LbGfJ0hPMeta?.path ?? "/dashboard/domains/whois",
    meta: indexw3LbGfJ0hPMeta || {},
    alias: indexw3LbGfJ0hPMeta?.alias || [],
    redirect: indexw3LbGfJ0hPMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/domains/whois/index.vue").then(m => m.default || m)
  },
  {
    name: indexFtYuxFtR1wMeta?.name ?? "dashboard-help",
    path: indexFtYuxFtR1wMeta?.path ?? "/dashboard/help",
    meta: indexFtYuxFtR1wMeta || {},
    alias: indexFtYuxFtR1wMeta?.alias || [],
    redirect: indexFtYuxFtR1wMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexRIIpWdUktDMeta?.name ?? "dashboard",
    path: indexRIIpWdUktDMeta?.path ?? "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    alias: indexRIIpWdUktDMeta?.alias || [],
    redirect: indexRIIpWdUktDMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: checkout_45successWg2sZIvYDxMeta?.name ?? "dashboard-local-domain-registration-service-checkout-success",
    path: checkout_45successWg2sZIvYDxMeta?.path ?? "/dashboard/local-domain-registration-service/checkout-success",
    meta: checkout_45successWg2sZIvYDxMeta || {},
    alias: checkout_45successWg2sZIvYDxMeta?.alias || [],
    redirect: checkout_45successWg2sZIvYDxMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/local-domain-registration-service/checkout-success.vue").then(m => m.default || m)
  },
  {
    name: indexDRHviHFhOtMeta?.name ?? "dashboard-local-domain-registration-service",
    path: indexDRHviHFhOtMeta?.path ?? "/dashboard/local-domain-registration-service",
    meta: indexDRHviHFhOtMeta || {},
    alias: indexDRHviHFhOtMeta?.alias || [],
    redirect: indexDRHviHFhOtMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/local-domain-registration-service/index.vue").then(m => m.default || m)
  },
  {
    name: indexW7xJWPe76nMeta?.name ?? "dashboard-profile",
    path: indexW7xJWPe76nMeta?.path ?? "/dashboard/profile",
    meta: indexW7xJWPe76nMeta || {},
    alias: indexW7xJWPe76nMeta?.alias || [],
    redirect: indexW7xJWPe76nMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexezgjwScBnSMeta?.name ?? "dashboard-profile-notifications",
    path: indexezgjwScBnSMeta?.path ?? "/dashboard/profile/notifications",
    meta: indexezgjwScBnSMeta || {},
    alias: indexezgjwScBnSMeta?.alias || [],
    redirect: indexezgjwScBnSMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XP2kLoUuk0Meta?.name ?? "dashboard-profile-organizations-id",
    path: _91id_93XP2kLoUuk0Meta?.path ?? "/dashboard/profile/organizations/:id()",
    meta: _91id_93XP2kLoUuk0Meta || {},
    alias: _91id_93XP2kLoUuk0Meta?.alias || [],
    redirect: _91id_93XP2kLoUuk0Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/profile/organizations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBTTwV34nSrMeta?.name ?? "dashboard-profile-organizations",
    path: indexBTTwV34nSrMeta?.path ?? "/dashboard/profile/organizations",
    meta: indexBTTwV34nSrMeta || {},
    alias: indexBTTwV34nSrMeta?.alias || [],
    redirect: indexBTTwV34nSrMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/profile/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lLsxKUPO3vMeta?.name ?? "dashboard-registrars-id",
    path: _91id_93lLsxKUPO3vMeta?.path ?? "/dashboard/registrars/:id()",
    meta: _91id_93lLsxKUPO3vMeta || {},
    alias: _91id_93lLsxKUPO3vMeta?.alias || [],
    redirect: _91id_93lLsxKUPO3vMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/registrars/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQkYxMWqJKzMeta?.name ?? "dashboard-registrars-godaddy",
    path: indexQkYxMWqJKzMeta?.path ?? "/dashboard/registrars/godaddy",
    meta: indexQkYxMWqJKzMeta || {},
    alias: indexQkYxMWqJKzMeta?.alias || [],
    redirect: indexQkYxMWqJKzMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/registrars/godaddy/index.vue").then(m => m.default || m)
  },
  {
    name: index2Yk5uCDZPEMeta?.name ?? "dashboard-registrars",
    path: index2Yk5uCDZPEMeta?.path ?? "/dashboard/registrars",
    meta: index2Yk5uCDZPEMeta || {},
    alias: index2Yk5uCDZPEMeta?.alias || [],
    redirect: index2Yk5uCDZPEMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/registrars/index.vue").then(m => m.default || m)
  },
  {
    name: indexg2kmA2a7U1Meta?.name ?? "dashboard-reports",
    path: indexg2kmA2a7U1Meta?.path ?? "/dashboard/reports",
    meta: indexg2kmA2a7U1Meta || {},
    alias: indexg2kmA2a7U1Meta?.alias || [],
    redirect: indexg2kmA2a7U1Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexsYg5vFPOgKMeta?.name ?? "dashboard-settings",
    path: indexsYg5vFPOgKMeta?.path ?? "/dashboard/settings",
    meta: indexsYg5vFPOgKMeta || {},
    alias: indexsYg5vFPOgKMeta?.alias || [],
    redirect: indexsYg5vFPOgKMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexiid3aQUQF8Meta?.name ?? "dashboard-support",
    path: indexiid3aQUQF8Meta?.path ?? "/dashboard/support",
    meta: indexiid3aQUQF8Meta || {},
    alias: indexiid3aQUQF8Meta?.alias || [],
    redirect: indexiid3aQUQF8Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexkK6SLClYNYMeta?.name ?? "domain-registration",
    path: indexkK6SLClYNYMeta?.path ?? "/domain-registration",
    meta: indexkK6SLClYNYMeta || {},
    alias: indexkK6SLClYNYMeta?.alias || [],
    redirect: indexkK6SLClYNYMeta?.redirect,
    component: () => import("/vercel/path0/pages/domain-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGnHiNaJdjPMeta?.name ?? "legal",
    path: indexGnHiNaJdjPMeta?.path ?? "/legal",
    meta: indexGnHiNaJdjPMeta || {},
    alias: indexGnHiNaJdjPMeta?.alias || [],
    redirect: indexGnHiNaJdjPMeta?.redirect,
    component: () => import("/vercel/path0/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: index9Oq1976rm7Meta?.name ?? "legal-privacy-policy",
    path: index9Oq1976rm7Meta?.path ?? "/legal/privacy-policy",
    meta: index9Oq1976rm7Meta || {},
    alias: index9Oq1976rm7Meta?.alias || [],
    redirect: index9Oq1976rm7Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexLdRQIa2Tt3Meta?.name ?? "legal-terms-of-service",
    path: indexLdRQIa2Tt3Meta?.path ?? "/legal/terms-of-service",
    meta: indexLdRQIa2Tt3Meta || {},
    alias: indexLdRQIa2Tt3Meta?.alias || [],
    redirect: indexLdRQIa2Tt3Meta?.redirect,
    component: () => import("/vercel/path0/pages/legal/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: indexrQEGMN64RqMeta?.name ?? "local-domain-registration-service",
    path: indexrQEGMN64RqMeta?.path ?? "/local-domain-registration-service",
    meta: indexrQEGMN64RqMeta || {},
    alias: indexrQEGMN64RqMeta?.alias || [],
    redirect: indexrQEGMN64RqMeta?.redirect,
    component: () => import("/vercel/path0/pages/local-domain-registration-service/index.vue").then(m => m.default || m)
  },
  {
    name: indexdtTG75hJaKMeta?.name ?? "search-for-domains",
    path: indexdtTG75hJaKMeta?.path ?? "/search-for-domains",
    meta: indexdtTG75hJaKMeta || {},
    alias: indexdtTG75hJaKMeta?.alias || [],
    redirect: indexdtTG75hJaKMeta?.redirect,
    component: () => import("/vercel/path0/pages/search-for-domains/index.vue").then(m => m.default || m)
  },
  {
    name: checkout_45successlyzrVCeVxlMeta?.name ?? "transfer-domains-checkout-success",
    path: checkout_45successlyzrVCeVxlMeta?.path ?? "/transfer-domains/checkout-success",
    meta: checkout_45successlyzrVCeVxlMeta || {},
    alias: checkout_45successlyzrVCeVxlMeta?.alias || [],
    redirect: checkout_45successlyzrVCeVxlMeta?.redirect,
    component: () => import("/vercel/path0/pages/transfer-domains/checkout-success.vue").then(m => m.default || m)
  },
  {
    name: index6LNRyE6A5YMeta?.name ?? "transfer-domains",
    path: index6LNRyE6A5YMeta?.path ?? "/transfer-domains",
    meta: index6LNRyE6A5YMeta || {},
    alias: index6LNRyE6A5YMeta?.alias || [],
    redirect: index6LNRyE6A5YMeta?.redirect,
    component: () => import("/vercel/path0/pages/transfer-domains/index.vue").then(m => m.default || m)
  }
]