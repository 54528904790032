import * as Realm from "realm-web";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("apollo:error", async (error) => {
    const { networkError, graphqlErrors, operation, forward } = error;

    if (networkError) {
      if (networkError.toString().includes("Received status code 401")) {
        // console.log("sxxxx");

        const config = useRuntimeConfig();

        const app = new Realm.App({ id: config.public.realmAppId });
        // Create an anonymous credential
        const credentials = Realm.Credentials.anonymous();
        // Authenticate the user
        const user = await app.logIn(credentials);
        // `App.currentUser` updates to match the logged in user
        // console.assert(user.id === app.currentUser.id);
        const { onLogin } = useApollo();

        if (user?.accessToken) onLogin(user.accessToken);

        // const { headers } = operation.getContext()
        // const authorization =`Bearer ${user?.accessToken}`
        // operation.setContext({ headers: { ...headers, authorization } })
        // forward(operation).subscribe()
        // return;
      }
      // console.log(error);
    }

    if (graphqlErrors) {
      // console.log(graphqlErrors);
    }

    // console.error(networkError)
    // console.error(graphqlErrors)
    // Handle different error cases
  });
});
