<template>
  <div class="min-h-screen text-primary-500 dark:text-primary-400 bg-slate-100 dark:bg-slate-900">
      <NuxtPage />
      <UNotifications />
  </div>
</template>

<style>
/* CSS FOR PAGE & LAYOUT TRANSITIONS */
/* .page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
} */
</style>

<script lang="ts" setup>
// const { locale } = useI18n()
// CD (KO on 20221219): The next 3 lines are used instead of "const { locale } = useI18n()" for i18n w/ Transitions.
// const { finalizePendingLocaleChange } = useI18n()
// const onBeforeEnter = async () => {
//   await finalizePendingLocaleChange()
// }

// useHead({
//   htmlAttrs: { lang: 'en' },
//   titleTemplate: (title) => `${title} | Project DP`,
// });

useSeoMeta({
  titleTemplate: (title) => `${title} | Domain Pool`,
  // description: 'Full domain management with DomainPool.io. Domain Pool makes registration, transfers, management and consolidated billing easy.',
  // ogType: 'website', // use 'article' for blog and guides
  // ogTitle: 'Better Domain Management',
  // ogDescription: 'Domain management can be difficult, especially across multiple registrars. Domain Pool makes registration, transfers, management and consolidated billing easy.',
  // ogUrl: 'https://domainpool.io',
  // ogImage: 'https://domainpool.io/images/pool-7.jpg',
  // twitterCard: 'summary_large_image',
  // twitterSite: '@domainpool_io',
  // twitterTitle: 'Better Domain Management',
  // twitterDescription: 'Domain management can be difficult, especially across multiple registrars. Domain Pool makes registration, transfers, management and consolidated billing easy.',
  // twitterImage: 'https://domainpool.io/images/pool-7.jpg',
})
</script>
