import * as Realm from "realm-web";

export default defineNuxtPlugin(async (nuxtApp) => {


  const config = useRuntimeConfig();

  const app = new Realm.App({ id: config.public.realmAppId });
  // Create an anonymous credential
  const credentials = Realm.Credentials.anonymous();
  // Authenticate the user
  const user = await app.logIn(credentials);

  // `App.currentUser` updates to match the logged in user
  // console.assert(user.id === app.currentUser.id);
  const { onLogin } = useApollo();

  if (user?.accessToken) onLogin(user.accessToken);
});
