import { createAuth0 } from "@auth0/auth0-vue";

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();

  const auth0 = createAuth0({
    domain: config.public.auth0Domain,
    clientId: config.public.auth0ClientId,
    cacheLocation: "localstorage",
    authorizationParams: {
      redirect_uri: config.public.auth0RedirectUrl,
    },
  });

  if (process.client) {
    nuxtApp.vueApp.use(auth0);

    return {
      provide: {
        auth0: auth0,
      },
    };
  }
});
