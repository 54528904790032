import { generateClasses } from "@formkit/themes";
import { genesisIcons } from "@formkit/icons";
import { createProPlugin, inputs } from "@formkit/pro";
import {
  createLocalStoragePlugin,
  createMultiStepPlugin,
  createAutoHeightTextareaPlugin,
  createAutoAnimatePlugin,
  createFloatingLabelsPlugin,
} from "@formkit/addons";
import myTailwindTheme from "./formkit.theme.js";
import "@formkit/addons/css/floatingLabels";
import "@formkit/addons/css/multistep";

const pro = createProPlugin("fk-3d08c04503", inputs);

const config: DefaultConfigOptions = {
  plugins: [
    pro,
    createLocalStoragePlugin({
      // plugin defaults:
      prefix: "dp",
      key: undefined,
      control: undefined,
      maxAge: 3600000, // 1 hour
      debounce: 200,
      beforeSave: undefined,
      beforeLoad: undefined,
    }),
    createMultiStepPlugin(),
    createAutoHeightTextareaPlugin(),
    createAutoAnimatePlugin(),
    createFloatingLabelsPlugin({
      useAsDefault: false, // defaults to false
    }),
  ],
  config: {
    classes: generateClasses(myTailwindTheme),
  },
  icons: {
    ...genesisIcons
  },
};


export default config;

